import React, { useState, useEffect } from "react"
import useForm from "../../utils/useForm"
import validate from "../../utils/validation/resetValidation"
import { Translations } from "../../utils/translations"
import {
  Container,
  Row,
  Column,
} from "atomic-core/components/patterns/00-base/grid"
import FormItem from "atomic-core/components/patterns/02-molecules/forms/FormItem"
import Heading from "atomic-core/components/patterns/01-atoms/texts/heading/Heading"
import Banner from "../shared/hero"
import Counter from "../shared/counter"
import Agenda from "../agenda"
import AuthErrorMessage from "../shared/auth"
import SuccessMessage from "../shared/auth/SuccessMessage"
import { Link } from "gatsby"

const GeneratePassword = props => {
  const [agendaData, setagendaData] = useState({
    days: [],
    presentations: [],
    activeTabIndex: 0,
    title: "Agenda",
    description: "",
  })
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${process.env.API_URL}/${process.env.EVENT_ID}/agenda`
      )
      let data = await response.json()
      if (data.result) {
        setagendaData({
          activeTabIndex: data.result.activeTabIndex,
          days: data.result.agenda.days,
          title: data.result.agenda.agenda_title,
          presentations: data.result.presentations,
        })
      }
    }
    fetchData()
  }, [])

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const handleGeneratePassword = async ({ email }, generatePasswordForm) => {
    try {
      if (!(typeof window !== `undefined`)) return false

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          eventId: process.env.EVENT_ID
        }),
      }

      let response = await fetch(process.env.API_URL + "/generatePassword", requestOptions)

      const contentType = response.headers.get("content-type")
      if (
        response.status !== 200 &&
        contentType &&
        contentType.indexOf("application/json") === -1
      ) {
        return setError(await response.text())
      }

      let data = await response.json()
      if (data && data.status === 200) {
        generatePasswordForm({})
        setSuccess(data.result)
      } else {
        generatePasswordForm({})
        setSuccess(true)
      }
    } catch (e) {
      setError(e.message || Translations.ERROR.SOMETHING_WENT_WRONG)
    }
  }

  const { values, errors, handleChange, handleSubmit } = useForm(
    handleGeneratePassword,
    validate,
    setError,
    setSuccess
  )

  return (
    <>
      <div className={"page auth"}>
        <Container fluid globalModifiers={"d-xs-flex xs-column height-full"}>
          <Row globalModifiers={""}>
            <Banner />
          </Row>
          <Row globalModifiers={"content"}>
            <Column
              sm={12}
              md={6}
              globalModifiers={
                "background-color-monochrome-1 pt-60 pr-120 pb-60 pl-70 color-secondary-2"
              }
            >
              {!success ? (
                <>
                  <Heading
                    headingType={"h2"}
                    globalModifiers={"pt-0 mb-0 font-primary-bold text-size-h3 color-monochrome-1"}
                  >
                    Generate password
                  </Heading>

                  <form onSubmit={handleSubmit} method={"post"} autoComplete={"off"}>
                    <FormItem globalModifiers={"mb-40 mt-30"}>
                      <div className={"form-item__control textfield"}>
                        <input
                          className={`form-control ${errors.email ? "form-control--error" : ""
                            }`}
                          name="email"
                          type="email"
                          value={values.email || ""}
                          onChange={handleChange}
                          placeholder={"Type email address"}
                          autoComplete={"off"}
                        />
                      </div>
                      <div className={"form-item__error-message"}>
                        {errors.email}
                      </div>
                    </FormItem>
                    <input
                      type="submit"
                      className={"button button--primary _a_reset"}
                      value={"Generate"}
                    />
                  </form>
                </>
              ) : (
                  <div>
                    <SuccessMessage
                      heading={Translations.SUCCESS.GENERATE_PASSWORD_HEADING}
                      text={Translations.SUCCESS.GENERATE_PASSWORD_TEXT}
                    />
                    <AuthErrorMessage text="If you don't receive the email, please" />
                    <Link to="/" className={"link text-underline color-secondary-1"}>
                      Go back to the login screen
                    </Link>
                  </div>
                )}
            </Column>
            <Column sm={12} md={6} globalModifiers={"pl-0 pr-0"}>
              <Counter />
              <div className="pt-30 pb-40 pl-40 pr-40">
                <Agenda
                  days={agendaData.days}
                  presentations={agendaData.presentations}
                  startIndex={agendaData.activeTabIndex}
                />
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default GeneratePassword
